.item-folder{
    color: brown;
}
.texto-center{
    text-align: center; 
}

.bordesSuaves{
    border: 1px solid #82F5D2;
    border-radius: 0.4rem;
}
.tertiary-color{
    color: var(--ion-color-tertiary)
}

.ContenedorCancionEdit{
  width: 99%;
}

.preCancion{
  color:rgb(115, 115, 115);
  margin: 0px 0px 0px 6px; padding: 0px;
  font-family: Roboto,"Helvetica Neue", sans-serif !important;
  font-size: "medium";
}
.Acorde{
  color: #5fadd9;
  font-size: small;
  font-weight: bold;
  /* line-height: 1rem; */
  margin: -20px 0px 0px 0px;
}

ion-fab-button::part(native) {
  background-color: rgb(167, 241, 229);
  border-radius: 15px;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,.3), 0px 1px 3px 1px rgba(0,0,0,.15);
  color: black;
}
    
ion-fab-button::part(native):hover::after {
  background-color: #3FEBCD;
}

ion-fab-button::part(native):active::after {
  background-color: #0a7fc4;
}

ion-modal#example-modal {
    --height: 50%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  ion-modal#example-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
  
  ion-modal#example-modal ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
  }

  ion-modal#modalAcordes {
    --height: 12%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --width:75%;
  }
  
  ion-modal#modalAcordes::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }
  
  ion-modal#modalAcordes ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
  }